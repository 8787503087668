<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import PageTitle from '@/components/page_title.svelte';
</script>

<PageTitle>About</PageTitle>

<p>
  The about page for Lentoverse.
</p>
<br>
<!-- <p class="text-xs">Find me in app/javascript/pages/about.svelte</p> -->
