<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import axios from 'axios';
  import { page } from "@inertiajs/svelte";
  import PageTitle from '@/components/page_title.svelte';
  import Form from "./_form.svelte";
  import ThemeToggle from '@/components/theme_toggle.svelte';

  let user = $page.props.user;
  let avatar = $page.props.avatar;
  let file_input;
  let is_uploading = false;
  let message = '';
  let error_message = '';
  let change_refresh = 0;

  $: {
    if ($page.props.message) {
      message = $page.props.message;
      setTimeout(() => message = '', 5000);
    }
    if ($page.props.errors) {
      error_message = $page.props.errors.join(', ');
      setTimeout(() => error_message = '', 5000);
    }
    if ($page.props.avatar !== undefined) {
      avatar = $page.props.avatar;
    }
  }

  async function handle_file_change(event) {
    const file = event.target.files[0];
    if (file) {
      is_uploading = true;

      try {
        const base64_data = await read_file_as_base64(file);
        const response = await axios.post('/avatar', { avatar: base64_data });
        
        if (response.data.avatar) {
          avatar = response.data.avatar;
          message = 'Avatar uploaded successfully';
        } else {
          error_message = 'Failed to upload avatar';
        }
      } catch (error) {
        console.error('Upload error:', error);
        error_message = 'Error uploading avatar';
      } finally {
        is_uploading = false;
        change_refresh++;
      }
    }
  }

  function read_file_as_base64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  function trigger_file_input() {
    file_input.click();
  }

  async function remove_avatar() {
    is_uploading = true;
    
    try {
      const response = await axios.delete('/avatar');
      
      if (response.data.avatar) {
        avatar = response.data.avatar;
        message = 'Avatar removed successfully';
      } else {
        error_message = 'Failed to remove avatar';
      }
    } catch(error) {
      console.error('Delete error: ', error);
      error_message = 'Error removing avatar';
    } finally {
      is_uploading = false;
      change_refresh++;
    }
  }

  function is_default_avatar(avatar_url) {
    return avatar_url.endsWith('default_avatar.png');
  }
</script>

<PageTitle>Update your profile</PageTitle>

<div class="settings-container w-full max-w-xs m-auto">
  <Form/>
  <div class="avatar-edit-container flex px-4 my-8">
    <div class="avatar_img_box px-3">
      <img class="avatar w-20 h-20 rounded-full object-cover" alt={user.name} src={avatar}>
    </div>
    <div class="avatar_edit_link_box flex flex-col justify-center">
      {#key change_refresh}
        <button on:click={trigger_file_input} class="btn1" disabled={is_uploading}> <!-- text-blue-500 hover:text-blue-700 -->
          {is_uploading ? 'Uploading...' : 'Change Avatar'}
        </button>
        {#if !is_default_avatar(avatar)}
          <button on:click={remove_avatar} class="text-red-500 hover:text-red-700 mt-2" disabled={is_uploading}>
            Remove Avatar
          </button>
        {/if}
      {/key}
      <input
        bind:this={file_input}
        type="file"
        accept="image/*"
        on:change={handle_file_change}
        class="hidden"
      />
    </div>
    {#key change_refresh}
      {#if message}
        <p class="text-green-500 text-xs mt-2">{message}</p>
      {/if}
      {#if error_message}
        <p class="text-red-500 text-xs mt-2">{error_message}</p>
      {/if}
    {/key}
  </div>
  <div class="theme-toggle-container flex flex-col items-center px-4 my-8">
    <div class="theme-toggle-title-box mb-2">
      <h3>Set the site-wide theme</h3>
    </div>
    <ThemeToggle />
  </div>
</div>
