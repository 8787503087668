<script>
    import { Link } from '@inertiajs/svelte';
    import MainNav from '../components/main_nav.svelte';
    import ThemeToggle from '@/components/theme_toggle.svelte';
    export let is_development;
</script>

<header class="header-container fixed top-0 left-0 right-0 z-50 bg-cyan-950">
  <div class="ribbon-container flex justify-between m-3">
    <div class="logo-container">
      <Link href="/" id="logo">Lentoverse</Link>
    </div>
    {#if is_development}
      <ThemeToggle />
    {/if}
    <MainNav />
  </div>
</header>
