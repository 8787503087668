<script>
  import { inertia } from '@inertiajs/svelte';
  let footer_tw = 'flex justify-between items-center mt-11 pt-1 mx-3 border-t-2 border-gray-300 text-gray-500';
</script>

<footer class="footer-container {footer_tw}">
  <div class="w-full flex justify-between">
    <div>
      <p class="text-gray-500 text-xs mt-2">
        &copy;2024 Lentobox All rights reserved.
      </p>
    </div>
    <nav>
      <ul class="flex flex-col md:flex-row md:justify-end">
        <li class="mr-0 md:mr-6 mb-2 md:mb-0">
          <a href="/about" use:inertia>About</a>
        </li>
        <li class="mr-0 md:mr-6 mb-2 md:mb-0">
          <a href="/contact" use:inertia>Contact</a>
        </li>
      </ul>
    </nav>
  </div>
</footer>
