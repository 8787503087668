import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Blockquote from '@tiptap/extension-blockquote';
import Hardbreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import OrderedList from '@tiptap/extension-ordered-list';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { MediaNode } from '@/rich_text_editor/media_node';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { generateHTML } from '@tiptap/html';

function compute_html_output( json ){
    // console.log("json: ", json);

    return generateHTML( json, [
        Document,
        Paragraph,
        Text,
        Bold,
        Italic,
        Blockquote,
        Hardbreak,
        Heading,
        HorizontalRule,
        OrderedList,
        BulletList,
        ListItem,
        Code,
        CodeBlock,
        Link,
        Image,
        MediaNode,
        Table,
        TableCell,
        TableHeader,
        TableRow
    ]);
}

export {
    compute_html_output,
}
