<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import PageTitle from '@/components/page_title.svelte';
</script>

<PageTitle>Contact</PageTitle>

<p>
  Email the admin:
  <a href="mailto:jstrausb@lentoverse.com">jstrausb@lentoverse.com</a>
</p>
