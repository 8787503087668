<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import { page } from '@inertiajs/svelte';
  import { capitalize } from '@/helpers/other';
  import PageTitle from '@/components/page_title.svelte';
  import UserInfo from '@/components/user_info.svelte';
  import Stats from '@/components/follower_stats.svelte';
  import User from '@/components/index_user.svelte';
  export let page_title;
  export let current_user;
  export let users; // following OR followers depending on the route
  export let following_count;

  console.log("props: ", $page.props);
  console.log("current_user: ", current_user);
  console.log("users: ", users);
  // console.log("following_count: ", following_count);
  let name = current_user.name;
  let avatar = current_user.avatar;
  let posts_data = current_user.posts_metadata;

  let follow_refresh = 0;
  function handle_follow_toggled(e){
      const { user_id, is_following, relationship_id, follower_count } = e.detail;
      users = users.map(u => {
          if(u.id === user_id){
              return {
                  ...u,
                  is_following,
                  relationship_id,
                  followers_count: follower_count
              };
          }
          return u;
      });

      following_count = is_following ? following_count + 1 : following_count - 1

      current_user = {
          ...current_user,
          following_count: following_count
      };
      // console.log("current_user.following_count:", current_user.following_count);
      follow_refresh++;
  }

</script>

<svelte:head>
  <title>{name}'s {capitalize(page_title)}</title>
</svelte:head>

<div class="show-follow-container mx-2 flex">
  <div class="show-follow-left-container w-1/3 mr-4">
    <div class="user-info-container mb-4">
      <UserInfo
        user={current_user}
        {avatar}
        {posts_data}
      />
    </div>
    <div class="user-stats-container">
      {#key follow_refresh}
        <Stats
          user={current_user}
          context={page_title}
        />
      {/key}
    </div>
  </div>
  <div class="show-follow-right-container w-2/3 mt-5 pt-2.5">
    <PageTitle>{capitalize(page_title)}</PageTitle>
    {#if users.length}
      <ul class="users-list-container">
        {#each users as user (user.id)}
          <User
            {user}
            {current_user}
            context={page_title}
            on:follow_toggled={handle_follow_toggled}
          />
        {/each}
      </ul>
    {:else}
      <p>No users to display.</p>
    {/if}
  </div>
</div>
