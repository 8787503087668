<script>
  import { inertia } from '@inertiajs/svelte';
  import Link from './event_link.svelte';
  import FollowButton from './follow_button.svelte';
  // import ImageLoader from './image_loader.svelte';
  // NB: this is the user component for the index page! (not show)
  export let user;
  export let current_user;
  export let admin;
  export let context;
</script>

<div class="UserContainer mt-5 p-3 border-b border-slate-200">
  <div class="UserFlexContainer flex items-start gap-x-4">
      <div class="avatar-box">
        <img class="avatar w-20 h-20 rounded-full object-cover" alt={user.name} src={user.avatar}>
      </div>
      <div class="user-details">
        <a
          href="/users/{user.id}"
          class="no-underline hover:underline text-2xl mt-0 mb-0.5"
          use:inertia
        >
          {user.name}
        </a>
        <!-- <p>email: {user.email}</p> -->
        <div class="user-actions my-2 flex gap-x-6">
          {#if admin}
            <div class="user-admin-panel">
              <!-- <a href="/logout" class="block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2" use:inertia="{ {  method: 'delete' } }">Log out</a> -->
              <!-- you'll want to refactor to use a confirmation modal -->
              <!-- <a href="{`/users/${user.id}`}" use:inertia="{ {  method: 'delete' } }">delete</a> -->
              <Link
                event_msg={'delete'}
                data={ { id: user.id, name: user.name } }
                on:delete
              >
                delete
              </Link>
            </div>
          {/if}
          <!-- NB: user.is_following refers to a user's state of being
            followed by the current_user -->
            {#if user.id !== current_user.id}
              <FollowButton
                user_id={user.id}
                initial_is_following={user.is_following}
                initial_relationship_id={user.relationship_id}
                initial_follower_count={user.followers_count}
                on:follow_toggled
              />
            {/if}
        </div>
      </div>
  </div>
</div>
