<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import { onMount } from 'svelte';
  import { page, useForm, inertia, Link } from "@inertiajs/svelte";
  import PageTitle from '@/components/page_title.svelte';
  export let flash;
  let bluebtn = "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
  let errortext = "text-red-500 text-xs italic";
  let show_password = false;
  let is_hovering = false;
  let is_password_hovering = false;

  console.log("$page: ", $page);
  console.log("flash: ", flash);

  // attempts to override the light background color applied by the browser in dark mode
  onMount(() => {
      const inputs = document.querySelectorAll('.tw_input_styles');
      inputs.forEach(input => {
          input.value = input.value;
      });
  });

  let form = useForm({
      email: null,
      password: null,
      remember: false
  });

  console.log("$form: ", $form);

  function submit(){
      // console.log("$form: ", $form);
      $form
      .transform( (data) => ({
          session: {
              ...data,
              remember: data.remember ? 'on' : '',
          }
      }))
      .post('/login');
  }
</script>

<style>
  .callout {
    width: 70px;
  }
</style>

<PageTitle>Log in</PageTitle>
<div class="form-container w-full max-w-xs m-auto">
  <form
    on:submit|preventDefault={submit}
    class="
      text-text-light dark:text-text-dark
      bg-background-light dark:bg-gray-800
      border border-transparent dark:border-gray-600
      shadow-md dark:shadow-lg
      rounded px-8 pt-6 pb-8 mb-4
      transition-colors duration-300
    "
  >
    <div class="form-input-box mb-4">
      <label for="email" class="tw_label_styles">Email</label>
      <input
        type="email"
        name="email"
        id="email"
        autocomplete="email"
        class="tw_input_styles ${ $form.errors.email ? 'border-red-500' : ''}"
        bind:value={$form.email}
      >
    </div>
    <div class="form-input-box mb-1 relative">
      <label for="password" class="tw_label_styles">Password</label>
      <div class="relative">
        {#if show_password}
          <input
            type="text"
            name="password"
            id="password"
            autocomplete="current-password"
            class="tw_input_styles ${ $form.errors.password ? 'border-red-500' : ''} mb-3"
            bind:value={$form.password}
          >
        {:else}
          <input
            type="password"
            name="password"
            id="password"
            autocomplete="current-password"
            class="tw_input_styles ${ $form.errors.password ? 'border-red-500' : ''} mb-3"
            bind:value={$form.password}
          >
        {/if}
      <button
        type="button"
        class="absolute top-0 bottom-3 right-0 px-3 flex items-center transition ease-in-out duration-300 
              { is_password_hovering ? 'bg-blue-500/40 dark:bg-blue-500/60 rounded-r' : '' }"
        on:click={() => show_password = !show_password}
        on:mouseover={() => is_password_hovering = true}
        on:focus={() => is_password_hovering = true}
        on:mouseout={() => is_password_hovering = false}
        on:blur={() => is_password_hovering = false}
      >
        <div class="relative top-0.5">
          {#if show_password}
            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" class="fill-current text-gray-700 dark:text-gray-300">
              <path d="M617.846-454.154 586-486q9-52.385-29.692-90.692Q517.615-615 466-606l-31.846-31.846q10.077-4.154 21.038-6.231 10.962-2.077 24.808-2.077 61.154 0 103.654 42.5 42.5 42.5 42.5 103.654 0 13.846-2.077 25.577-2.077 11.731-6.231 20.269Zm126.462 122.923L714-358q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-31.231-31.231q34.846-13.154 70.923-18.962Q443.769-760 480-760q130.231 0 238.231 71.577T876.923-500q-21.461 48.231-54.346 90.654-32.884 42.423-78.269 78.115Zm44.615 216.77L633.231-269.692q-26.539 11.769-65.885 20.731Q528-240 480-240q-131 0-238.231-71.577T83.077-500q23.307-53 61.461-99.269 38.154-46.269 81.462-77.654l-111.539-112 28.308-28.308 674.462 674.462-28.308 28.308ZM254.307-648.615Q219.923-624.154 184-584.308 148.077-544.461 128-500q50 101 143.5 160.5T480-280q34.615 0 69.769-6.731 35.154-6.73 52.846-13.577L537.385-366q-9.462 5.308-26.385 8.731-16.923 3.423-31 3.423-61.154 0-103.654-42.5-42.5-42.5-42.5-103.654 0-13.308 3.423-29.846 3.423-16.539 8.731-27.539l-91.693-91.23ZM541-531Zm-112.539 56.539Z"/>
            </svg>
          {:else}
            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" class="fill-current text-gray-700 dark:text-gray-300">
              <path d="M480.181-353.846q60.973 0 103.473-42.681t42.5-103.654q0-60.973-42.681-103.473t-103.654-42.5q-60.973 0-103.473 42.681t-42.5 103.654q0 60.973 42.681 103.473t103.654 42.5ZM480-392q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm.11 152q-129.956 0-236.879-70.731Q136.307-381.461 83.077-500q53.23-118.539 160.044-189.269Q349.934-760 479.89-760q129.956 0 236.879 70.731Q823.693-618.539 876.923-500q-53.23 118.539-160.044 189.269Q610.066-240 480.11-240ZM480-500Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/>
            </svg>
          {/if}
        </div>
      </button>
      <div
        class="
          callout absolute -right-3 top-0 transform -translate-y-full -mt-0
          bg-gray-200 bg-gray-700 dark:bg-gray-700 text-gray-100 dark:text-gray-200
          text-xs rounded py-1 px-2 whitespace-normal text-center transition-opacity duration-300
          " 
        style="opacity: {is_password_hovering ? 1 : 0}; pointer-events: {is_password_hovering ? 'auto' : 'none'};"
      >
        {#if show_password}
          Hide<br>password
        {:else}
          Show<br>password
        {/if}
        <div
          class="
            arrow absolute left-1/2 transform -translate-x-1/2 -bottom-2 w-0 h-0 border-l-8 border-r-8 border-t-8 border-solid
            border-t-gray-700 dark:border-t-gray-700 border-r-transparent
            border-l-transparent
            "
          >
        </div>
      </div>
    </div>
    <div class="forgot-password pl-1 mb-2">
      <p class="text-sm"><a href="/password_resets/new" id="forgot-password" class="no-underline" use:inertia>Forgot password?</a></p>
    </div>
    <div class="flex items-center justify-between my-4">
      <button type="submit" class={bluebtn} disabled={$form.processing}>Continue</button>
      <div class="form-input-box my-4 inline-block align-baseline flex gap-x-1 items-start">
        <input type="checkbox" name="remember_me" id="remember_me" class="form-checkbox bg-background-light dark:bg-background-dark m-1 rounded-sm" bind:checked={$form.remember} />
        <label for="remember_me" class="inline-block tw_label_styles">Remember Me</label>
      </div>
    </div>
    <div class="new-account text-center">
      <p class="text-sm">Don't have an account? <Link href="/signup" id="signup" class="no-underline">Sign up</Link></p>
    </div>
  </form>
</div>
