// In lazy_loading.js
import { writable, get } from 'svelte/store';

const all_items_loaded = writable(false);
const item_list = writable([]);
const current_page = writable(1);
const is_loading = writable(false);
const is_error = writable(false);

const cached_pages = writable(new Map());

const reset_cached_pages = (context) => {
    console.log(`Resetting cached pages for context: ${context}`);
    cached_pages.update(cache => {
        cache.set(context, new Map());
        return cache;
    });
};

const check_cache_context = (context) => {
    const cache = get(cached_pages);
    if (!cache.has(context)) {
        reset_cached_pages(context);
    }
    return cache.get(context);
};

const update_cache = (context, page, data) => {
    cached_pages.update(cache => {
        if (!cache.has(context)) {
            cache.set(context, new Map());
        }
        cache.get(context).set(page, data);
        return cache;
    });
};

const get_cached_data = (context, page) => {
    const cache = get(cached_pages);
    return cache.has(context) ? cache.get(context).get(page) : null;
};

function delete_item_from_cache( resource, item_id, predicate = (item, id) => item.id !== id ){
    cached_pages.update(cache => {
        for(const [context, context_cache] of cache){
            let cache_updated = false;
            for(const [page, page_data] of context_cache){
                if(Array.isArray(page_data[resource])){
                    const updated_items = page_data[resource].filter(item => predicate(item, item_id));
                    if(updated_items.length !== page_data[resource].length){
                        context_cache.set(page, {
                            ...page_data,
                            [resource]: updated_items
                        });
                        cache_updated = true;
                        break;
                    }
                }
            }
            if(cache_updated) break;
        }
        return cache;
    });
}

/* Below is useful if you need to delete an item that has a custom id prop
  e.g., `user_id` instead of `id`
  First a generic use example:

  For posts (using default 'id' field)
  function delete_post_from_cached_pages(post_id) {
      delete_item_from_cache(post_id);
  }

  But if users have a custom ID field, like `user_id`
  const delete_user_predicate = create_delete_predicate('user_id');
  function delete_user_from_cached_pages(user_id) {
      delete_item_from_cache(user_id, delete_user_predicate);
  }
*/
function create_delete_predicate(id_field = 'id'){
    return function(item, id){
        return item[id_field] !== id;
    }
}

function remove_duplicates(array, key) {
    return Array.from(new Map(array.map(item => [item[key], item])).values());
}

async function fetch_items(context, resource, endpoint, page, items_per_page, query_data = {}) {
    try {
        const cached_data = get_cached_data(context, page);
        if (cached_data) {
            return cached_data;
        }

        const query_params = new URLSearchParams({
            resource: resource,
            page: page.toString(),
            per_page: items_per_page.toString(),
            ...query_data
        });
    
        const url = `${endpoint}?${query_params.toString()}`;
        
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch ${resource}.`);
        }
        
        const data = await response.json();

        const items = data[resource];
        const has_more = data.has_more;

        const result = { items, has_more };
        update_cache(context, page, result);

        return result;
    } catch (error) {
        throw error;
    }
}

async function load_more_items(context, resource, endpoint, items_per_page, query_data) {
    if (get(all_items_loaded) || get(is_loading) || get(is_error)) {
        // Don't proceed if all items are loaded, currently loading, or there was an error
        return;
    }

    is_loading.set(true);
    is_error.set(false);
    try {
        const page = get(current_page);
        const { items, has_more } = await fetch_items(context, resource, endpoint, page, items_per_page, query_data);
        
        const new_items = remove_duplicates([...get(item_list), ...items], 'id');
        item_list.set(new_items);
        
        current_page.update(p => p + 1);
        all_items_loaded.set(!has_more);
    } catch (error) {
        console.error("Error loading more items:", error);
        is_error.set(true);
    } finally {
        is_loading.set(false);
    }
}

export {
    all_items_loaded,
    item_list,
    current_page,
    is_loading,
    is_error,
    cached_pages,
    check_cache_context,
    update_cache,
    get_cached_data,
    reset_cached_pages,
    delete_item_from_cache,
    create_delete_predicate,
    load_more_items,
    fetch_items,
    remove_duplicates
};
