<script>
  import { page } from '@inertiajs/svelte';
  import UserInfo from '@/components/user_info.svelte';
  import Stats from '@/components/follower_stats.svelte';
  import Post from '@/pages/posts/_form.svelte';
  import HomeFeed from '@/components/user_home/user_home_feed.svelte';

  let props = $page.props;
  let current_user = props.auth.user;
  let avatar = props.avatar
  let posts_data = props.posts_metadata;
  let new_post = props.new_post;
  let feed = props.feed;

  function refresh(event) {
    // console.log("Refresh called with event: ", event);
    
    if (event && event.detail && event.detail.props) {
      // console.log("Refreshing from HomeFeed event");
      let props = event.detail.props;
      // note that if you refresh the feed from the response here
      // it results in display problems.
      // there's an evident tradeoff and deleting any number of freshly created posts
      // usually results in display bugs where all fresh posts are deleted
      // feed = props.feed;
      // console.log("feed: ", props.feed);
      posts_data = props.posts_metadata;
    } else {
      // console.log("Refreshing from $page.props");
      feed = $page.props.feed;
      posts_data = $page.props.posts_metadata;
    }
    
    // console.log("Updated data:", { feed, posts_data, current_user });
  }
</script>

<div class="user-home-container mx-2 lg:flex">
  <div class="user-home-left-container lg:w-2/3 lg:mr-4">
    <div class="user-info-container mb-4">
        <UserInfo
          user={current_user}
          {avatar}
          {posts_data}
        />
      <div class="user-follower-stats-container">
        <Stats
          user={current_user}
          context={'home'}
        />
      </div>
    </div>
    <div class="user-post-create-container mb-6 lg:mb-0">
      <Post
        {new_post}
        on:submit={refresh}
      />
    </div>
  </div>
  <div class="user-feed-container lg:w-2/3">
      <HomeFeed
        {current_user}
        posts={feed}
        on:delete={refresh}
      />
  </div>
</div>
