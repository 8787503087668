<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { flip } from 'svelte/animate';
  import { fade, scale } from 'svelte/transition';
  import axios from 'axios';
  import {
      all_items_loaded,
      item_list,
      current_page,
      is_loading,
      is_error,
      check_cache_context,
      update_cache,
      get_cached_data,
      delete_item_from_cache,
      load_more_items
  } from '@/helpers/lazy_loading.js';
  import Post from '@/pages/posts/_post.svelte';
  import Modal from '@/components/modal.svelte';

  export let current_user;
  export let posts;

  const dispatch = createEventDispatcher();
  let resource = 'posts';
  let context = 'home_feed';
  let sentinel;
  $all_items_loaded = false;
  let items_per_page = 10;
  let x_post;
  let delete_post_modal;

  const initial_data = posts;
  $item_list = initial_data;

  onMount(() => {
      check_cache_context(context);

      if(!get_cached_data(context, $current_page)) {
          update_cache(context, $current_page, { [resource]: initial_data });
      }
  
      $current_page += 1;

      if(sentinel){
          observer.observe(sentinel);
      }

      if(initial_data.length == 0){
          $all_items_loaded = true;
      } else if(should_load_more_items()){
          // console.log('mount load more items')
          load_more_items(context, resource, '/home/next', items_per_page, query_data);
      }
  });

  $: {
      $item_list = posts;
  }

  $: query_data = {
      context: 'home',
      user_id: current_user.id,
      last_created_at: $item_list.length > 0 ? $item_list.slice(-1)[0].created_at : -1
  };

  function should_load_more_items() {
      // console.log("all_items_loaded: ", $all_items_loaded ? "yes" : 'no')
      // console.log("is_loading: ", $is_loading ? "yes" : 'no')
      // console.log("is_error: ", $is_error ? "yes" : 'no')
      return !$all_items_loaded && !$is_loading && !$is_error;
  }

  const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && should_load_more_items()) {
          query_data.last_created_at = $item_list.length > 0 ? $item_list.slice(-1)[0].created_at : -1;
          // console.log('intersection load more items')
          load_more_items(context, resource, '/home/next', items_per_page, query_data);
      }
  });

  function handle_retry() {
      if (should_load_more_items()) {
          load_more_items(context, resource, '/home/next', items_per_page, query_data);
      } else if ($all_items_loaded) {
          alert("All posts have been loaded");
      }
  }

  const delete_post_confirmation_modal = {
      name: 'delete_post_confirmation',
      title: 'Delete this post?',
      buttons: [
          ['Cancel', 'btn1', null],
          ['Delete', 'btn dbtn', 'delete']
      ]
  };

  function set_delete_post_confirmation_modal(post) {
      x_post = post;
      delete_post_modal.show_modal();
  }

  async function delete_post() {
      $is_loading = true;

      try {
          const response = await axios.delete(`/posts/${x_post.id}`);
          if (response.data.success){
              delete_refresh(response.data);
          } else {
              throw new Error(response.data.message || 'Failed to delete post');
          }
      } catch (error){
          console.error('Error deleting post:', error);
          alert('Failed to delete post. Please try again.');
      } finally {
          $is_loading = false;
      }
  }

  function delete_post_from_cached_pages(post_id){
      delete_item_from_cache(resource, post_id);
  }

  function delete_refresh(data){
      $item_list = $item_list.filter(post => post.id !== x_post.id);
      delete_post_from_cached_pages(x_post.id);
      dispatch('delete', data);
  }
</script>

{#if $item_list.length}
  <ul class="feed-container">
    {#each $item_list as post (post.id)}
      <li
        class="animated-li"
        animate:flip={{ duration: 200 }}
        in:scale|local={{ duration: 200, delay: 200 }}
        out:fade|local={{ duration: 200 }}
      >
        <Post
          {context}
          {post}
          {current_user}
          on:delete={(e) => set_delete_post_confirmation_modal(e.detail)}
        />
      </li>
    {/each}
    <div bind:this={sentinel}></div>

    {#if $is_loading}
      <div class="loading-indicator">Loading...</div>
    {/if}

    {#if $is_error}
      <div class="error-message">
        Failed to fetch posts. Please <button on:click={handle_retry}>Retry</button>.
      </div>
    {/if}
  </ul>
{/if}

<Modal
  {...delete_post_confirmation_modal}
  bind:this={delete_post_modal}
  on:delete={() => delete_post()}
>
  <div slot=body>
    <p>This action cannot be undone.</p>
  </div>
</Modal>
