<script>
  import { Link } from '@inertiajs/svelte';
  import EventLink from '@/components/event_link.svelte';
  import { format_time_ago, is_valid_json, parse_str_as_json } from '@/helpers/other.js';
  import { compute_html_output } from '@/helpers/post_helper.js';
  export let context;
  export let post;
  export let current_user;
  // console.log("post: ", post);
  // console.log("post user: ", user);
  let id = post.id;

  let content_is_json = is_valid_json(post.content);
  let content_json = null;
  if(content_is_json){
      content_json = parse_str_as_json(post.content);
      // content_json['render'] = true;
  }
</script>

<div
  id={`user-post-${id}`}
  class="user-post border-b border-gray-200 dark:border-gray-700 mx-4 my-6"
>
  <div class="user-post-header-container flex justify-between mx-4 mt-4">
    <!-- NB: the user info isn't needed for the user show page -->
    <!-- therefore, a flag needs to be set to modify the display -->
    <!-- depending on the parent container -->
      <div class="user-post-info-container flex gap-x-2 items-center text-sm">
        <!--  && current_user.id == post.user_id -->
        {#if context == 'user_show'}
            <img class="avatar w-10 h-10 rounded-full object-cover" alt={post.user.name} src={post.user.avatar_url}>
            <div class="user-post-username">{post.user.name}</div>
        {:else}
            <Link
              href="/users/{post.user_id}"
            >
              <img class="avatar w-10 h-10 rounded-full object-cover" alt={post.user.name} src={post.user.avatar_url}>
            </Link>
            <Link
              href="/users/{post.user_id}"
              class="user-post-username no-underline hover:underline"
            >
              {post.user.name}
            </Link>
        {/if}
        <span class="user-post-activity text-gray-500">
            posted
        </span>
        <span class="user-post-timestamp text-gray-500">
          {format_time_ago(post.created_at)}
        </span>
      </div>
      <div class="post-action-container">
        <!-- provide delete action only if the post's user_id matches current user.id -->
        {#if current_user.id == post.user_id}
          <EventLink
            event_msg={'delete'}
            data={ { id: post.id } }
            styles={'text-sm no-underline cursor-pointer'}
            on:delete
          >
            delete
          </EventLink>
        {/if}
      </div>
  </div>
  <div class="user-post-body-container mx-4 my-2 pl-4 text-text-light dark:text-text-dark {content_is_json ? 'rte-render' : ''}">
    {#if content_is_json}
      {@html compute_html_output(content_json)}
    {:else}
      {post.content}
    {/if}
  </div>
  <div class="user-post-footer-container flex mx-4 mb-4">
    <!-- if post replies.length, post is a kind of thread -->
      <!-- thread open / close toggle control -->
    <!-- post upvote / downvote control and display -->
    <!-- post reply button -->
    <!-- post share button -->
    <!-- post minimenu -->
      <!-- select post "report" -->
      <!-- select post "save" -->
  </div>
</div>

