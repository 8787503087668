<script>
  import { onMount, onDestroy } from "svelte";
  import { Editor } from 'svelte-tiptap';
  import { StarterKit } from '@tiptap/starter-kit';
  import Placeholder from '@tiptap/extension-placeholder';
  import Link from '@tiptap/extension-link';
  import Image from '@tiptap/extension-image';
  import Dropcursor from '@tiptap/extension-dropcursor';
  // import Gapcursor from '@tiptap/extension-gapcursor';
  import BubbleMenu from '@tiptap/extension-bubble-menu';
  import EditorToolbar from "./editor_toolbar.svelte";
  import Modal from './modal.svelte';
  import RteLinkBubbleMenu from "./rte_link_bubble_menu.svelte";
  import Table from '@tiptap/extension-table';
  import TableCell from '@tiptap/extension-table-cell';
  import TableHeader from '@tiptap/extension-table-header';
  import TableRow from '@tiptap/extension-table-row';
  import Text from '@tiptap/extension-text';
  import { get_node_at_cursor, get_node_info } from '@/helpers/nodes';
  import { is_valid_image_file, insert_rte_image_view, drop_insert_rte_image_view } from '@/helpers/image_helper';

  // custom extension
  import { ImageNode } from '@/rich_text_editor/image_node';
  import EditorTableToolbar from "./editor_table_toolbar.svelte";

  export let content;
  let element;
  let editor;
  // let last_clicked_pos = null;
  // let last_link_href = null;
  let bbl_menu = null;

  let placeholder = `Compose a new post...`;

  onMount(() => {
      const nonce = document.body.dataset.cspNonce;
      console.log("content at mount: ", content);
      editor = new Editor({
          content: [ content && content.length ? content : '' ],
          element: element,
          extensions: [
              StarterKit,
              Placeholder.configure({
                  placeholder: placeholder,
              }),
              Link.configure({
                  openOnClick: false,
              }),
              Image,
              Dropcursor, // turn off for work on tables
              // Gapcursor,
              BubbleMenu.configure({
                  element: document.querySelector('.menu'),
                  shouldShow: ({ editor }) => {
                      return editor.isActive('link');
                  },
                  tippyOptions: {
                      placement: 'bottom-end',
                      offset: [0,5],
                  }
              }),
              ImageNode,
              Table.configure({
                  resizable: true,
              }),
              TableRow,
              TableHeader,
              TableCell,
          ],
          onTransaction: () => {
              editor = editor;
              const json = editor.getJSON();
              // console.log("transaction handler")
              // content = convert_image_nodes(JSON.stringify(json), false);
              content = JSON.stringify(json);
          },
          editorProps: {
              handleDrop: ( view, event, slice, moved ) => {
                  handle_drop( view, event, slice, moved );
              }
          },
          injectNonce: nonce,
      });
  });

  onDestroy(() => {
      if(editor){
          editor.destroy();
      }
  }); 

  function handle_toggle_bold(e){
      editor.chain().focus().toggleBold().run();
  }

  function handle_toggle_italic(e){
      editor.chain().focus().toggleItalic().run();
  }

  function handle_toggle_heading(e){
      const { level } = e.detail;
      editor.chain().focus().toggleHeading({ level }).run();
  }

  function handle_toggle_list(e){
      const { type } = e.detail;
      if(type == 'bullet_list'){
          editor.chain().focus().toggleBulletList().run();
      } else if(type == 'ordered_list'){
          editor.chain().focus().toggleOrderedList().run();
      }
  }

  function handle_toggle_blockquote(e){
      editor.chain().focus().toggleBlockquote().run();
  }

  function handle_toggle_code(e){
      editor.chain().focus().toggleCode().run();
  }

  function handle_toggle_codeblock(e){
      editor.chain().focus().toggleCodeBlock().run();
  }

  const add_link_modal_data = {
      name: 'add_link',
      title: 'Add link',
      // width: 'w-2/3',
      z_index: 'z-50',
      buttons: [
        [ 'Cancel', 'btn1', null ],
        [ 'Save', 'bluebtn', 'save' ]
      ]
  };

  const edit_link_modal_data = {
      name: 'edit_link',
      title: 'Edit link',
      // width: 'w-2/3',
      z_index: 'z-50',
      buttons: [
        [ 'Cancel', 'btn1', null ],
        [ 'Save', 'bluebtn', 'save' ]
      ]
  };

  let link_modal;
  function handle_set_link(e){
      set_link_modal_data();
      link_modal.show_modal();
  }

  let url = '';
  let link_text = '';
  let link_modal_data;
  let node_info;
  function set_link_modal_data(){
      let node = get_node_at_cursor(editor);
      node_info = get_node_info(node);
      if(node_info.type == 'a' && node_info.href){
          link_modal_data = edit_link_modal_data;
      } else {
          link_modal_data = add_link_modal_data;
      }
  }

  let url_error = '';
  let link_input;
  let should_validate = false;
  function validate_url(){
      try {
          new URL(url);
          url_error = '';
      } catch(_){
          if(url === ''){
              url_error = 'Please fill out this field.';
          } else {
              url_error = 'Invalid URL';
          }
      }
  }

  function check_and_validate(){
      if(url){
          validate_url();
      } else if(!should_validate){
          should_validate = true;
          return;
      }
  }

  let modal_active = false;
  function handle_open_modal(){
      bbl_menu = null;
      modal_active = true;
      reset_modal_state();
      if(link_modal_data.name.includes('add') && !editor.state.selection.empty){
          preset_link_text();
      } else if(link_modal_data.name.includes('edit') && node_info.href){
          link_text = node_info.text;
          url = node_info.href;
      }
  }

  function preset_link_text(){
      const { from, to } = editor.state.selection;
      link_text = editor.state.doc.textBetween(from, to);
  }

  function handle_close_modal(){
      modal_active = false;
      reset_modal_state();
  }

  function reset_modal_state(){
      link_text = '';
      url = '';
      url_error = '';
      should_validate = false;
  }

  function handle_cancel(){
      reset_cursor();
  }

  function reset_cursor(){
      // console.log('reset cursor')
      const start_pos = 0;
      editor.commands.setTextSelection( start_pos );
      document.activeElement.blur();
  }

  $: allow_save = url && !url_error

  function handle_save(){
      if(url && !url_error){
          set_link(url, link_text);
      }
  }

  function set_link(url, link_text){
      if(url === null) return;

      if(url === ''){
          // editor.chain().focus().extendMarkRange('link').unsetLink().run();
          delete_link();
          return;
      }
      // console.log("setting: ", `{ url: ${url}, text: ${link_text} }`)
      editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink( { href: url } )
          .command(( { tr } ) => {
              tr.insertText( link_text )
              return true;
          })
          .run();
  }

  function delete_link(){
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
  }

  // params `view` and `event` are unused, but their positions as function params
  // is what's expected by the ProseMirror library when `handle_click` is set as
  // a callback method on the Editor object.
  // let bbl_menu_active = false;
  // function handle_click(view, pos, event){
  //     last_clicked_pos = pos;
  //     bbl_menu_active = should_show_bubble_menu({ state: view.state });
  // }

  
  // function should_show_bubble_menu( { state } ){
  //     if(!last_clicked_pos){
  //         return false;
  //     }

  //     const { doc } = state;
  //     const resolved_pos = doc.resolve( last_clicked_pos );
  //     const marks = resolved_pos.marks();

  //     const link_mark = marks.find( mark => mark.type.name === 'link' );

  //     if(link_mark){
  //         console.log("link_mark: ", link_mark);
  //         last_link_href = link_mark.attrs.href;
  //         return true;
  //     }
  //     console.log("no link_mark");
  //     last_link_href = null;
  //     return false;
  // }

  function handle_set_image(e){
      // set condition: prompt user to set image from web
      // const url = window.prompt('URL');
      // if(url){
      //     editor.chain().focus().setImage({ src: url }).run();
      // }
      const view = editor.view; 
      upload_image( view );
  }

  function upload_image( view ){
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = (e) => {
          const file = e.target.files[0];
          if(file){
              if(!is_valid_image_file( file )){
                  window.alert("Images need to be a valid JPEG or PNG image file under 5MB.");
                  return;
              }
              insert_rte_image_view( file, view );
          }
      }
      input.click();
  }

  // checks if the drop is not a move event (as in moving an image inside the editor)
  // and that drop involves an external file (i.e., an image from the filesystem)
  function drop_is_external( moved, event ){
      return !moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0];
  }

  function handle_drop( view, event, slice, moved ){
      if(drop_is_external( moved, event )){
          event.preventDefault();
          let file = event.dataTransfer.files[0]; // the dropped file
          
          if(!is_valid_image_file( file )){
              window.alert("Images need to be a valid JPEG or PNG image file under 5MB.");
              return;
          }
          
          drop_insert_rte_image_view( file, view, event, editor );
          return true; // handled
      }
      return false; // not handled, use default behavior
  }

  // Check if the custom node is registered in the schema
  // const { schema } = view.state;
  // console.log("Schema node:", schema.nodes);

  let table_work = false;

</script>


{#if editor}
  <EditorToolbar
    {editor}
    on:toggle_bold={handle_toggle_bold}
    on:toggle_italic={handle_toggle_italic}
    on:toggle_heading={(e) => handle_toggle_heading(e)}
    on:toggle_list={(e) => handle_toggle_list(e)}
    on:toggle_blockquote={(e) => handle_toggle_blockquote(e)}
    on:toggle_code={(e) => handle_toggle_code(e)}
    on:toggle_codeblock={(e) => handle_toggle_codeblock(e)}
    on:set_link={(e) => handle_set_link(e)}
    on:set_image={(e) => handle_set_image(e)}
  />
{/if}

{#if table_work}
  <hr>
  <EditorTableToolbar
    {editor}
  />
{/if}

<div
  class="menu"
>
  {#if !modal_active}
    <RteLinkBubbleMenu
      {editor}
      on:set={() => handle_set_link()}
      bind:this={bbl_menu}
    />
  {/if}
</div>
<div
  bind:this={element}
/>

<Modal
  bind:this={link_modal}
  {...link_modal_data}
  disabled={!allow_save}
  on:save={() => handle_save()}
  on:open={() => handle_open_modal()}
  on:close={() => handle_close_modal()}
  on:cancel={() => handle_cancel()}
>
  <div slot=body>
    <div class="link-text-input-container mb-4">
      <!-- svelte-ignore a11y-autofocus -->
      <input
        type="text"
        placeholder="Text"
        bind:value={link_text}
        class="tw_input_styles w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        autofocus
      >
    </div>
    <div class="url-input-container mb-4">
      <input
        type="text"
        placeholder="Link *"
        bind:value={url}
        on:input={check_and_validate}
        on:blur={validate_url}
        class="tw_input_styles w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        class:border-red-500={url_error}
        bind:this={link_input}
      >
      {#if url_error}
          <p class="text-red-500 text-sm mt-1">{url_error}</p>
      {/if}
    </div>
  </div>
</Modal>
