<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import axios from 'axios';
  import { onMount } from 'svelte';
  import { flip } from 'svelte/animate';
  import { fade, scale } from 'svelte/transition';
  import { page } from '@inertiajs/svelte';
  import {
      all_items_loaded,
      item_list,
      current_page,
      is_loading,
      is_error,
      check_cache_context,
      update_cache,
      get_cached_data,
      delete_item_from_cache,
      load_more_items
  } from '@/helpers/lazy_loading.js';
  import { capitalize } from '@/helpers/other.js'
  import PageTitle from '@/components/page_title.svelte';
  import Post from '@/pages/posts/_post.svelte';
  import Stats from '@/components/follower_stats.svelte';
  import Modal from '@/components/modal.svelte';
  export let flash;
  console.log("$page.props: ", $page.props);
  let user = $page.props;
  let posts = user.posts;
  let posts_metadata = user.posts_metadata;
  let is_following = user?.is_following;
  let relationship_id = user?.relationship_id;
  const avatar = $page.props.avatar;
  const current_user = $page.props.auth.user;
  let resource = 'posts';
  let context = 'user_show';
  let sentinel;
  let x_post;

  $all_items_loaded = false;
  let items_per_page = 10;

  const initial_data = posts;
  $item_list = initial_data;
  
  onMount(() => {
      check_cache_context(context);

      if(!get_cached_data(context, $current_page)) {
          update_cache(context, $current_page, { [resource]: initial_data });
      }
  
      $current_page += 1;

      if(sentinel){
          observer.observe(sentinel);
      }

      if(initial_data.length == 0){
          $all_items_loaded = true;
      } else if(should_load_more_items()){
          // console.log('mount load more items')
          load_more_items(context, resource, '/posts/next', items_per_page, query_data);
      }
  });

  $: {
      $item_list = posts
  };

  $: query_data = {
      context: 'show',
      user_id: user.id,
      last_created_at: $item_list.length > 0 ? $item_list.slice(-1)[0].created_at : -1
  };

  function should_load_more_items(){
      return !$all_items_loaded && !$is_loading && !$is_error;
  }

  const observer = new IntersectionObserver(entries => {
      if( entries[0].isIntersecting && should_load_more_items() ){
          load_more_items( context, resource, '/posts/next', items_per_page, query_data );
      }
  });

  const delete_post_confirmation_modal = {
      name: 'delete_post_confirmation',
      title: 'Delete this post?',
      buttons: [
        [ 'Cancel', 'btn1', null ],
        [ 'Delete', 'btn dbtn', 'delete' ]
      ]
  };

  let delete_post_modal;
  function set_delete_post_confirmation_modal(x){
      x_post = $item_list.find( p => p.id == x.id );
      delete_post_modal.show_modal();
  }

  async function delete_post(){
      try {
        const response = await axios.delete(`/posts/${x_post.id}`) //, {

        if (response.data.success) {
          delete_refresh(response.data);
        } else {
          throw new Error(response.data.message || 'Failed to delete post');
        }
      } catch (error) {
        console.error('Error deleting post:', error);
        alert('Failed to delete post. Please try again.');
      }
  }

  function delete_post_from_cached_pages(post_id){
      delete_item_from_cache(resource, post_id);
  }

  function delete_refresh(data){
      $item_list = $item_list.filter(post => post.id !== x_post.id);
      delete_post_from_cached_pages(x_post.id);
      posts_metadata = data.props.posts_metadata;
  }

  let follow_refresh = 0;
  let follow_process_active = false;
  async function toggle_follow(){
      if(follow_process_active) return;
      follow_process_active = true;

      try {
          let response;
          if(is_following){
              response = await axios.delete(`/relationships/${relationship_id}`);
          } else {
              response = await axios.post('/relationships', { followed_id: user.id })
          }

          const data = response.data;

          console.log("Response data: ", data);
          is_following = data.following;
          relationship_id = data.relationship_id;
          user.followers_count = data.followers_count;
          follow_refresh++;
      } catch(error){
          console.error("Error setting follow state:", error);
          // Handle error
      } finally {
          follow_process_active = false;
      }
  }

</script>

<div class="user-show-container">
    <div class="user-show-header my-5 pt-2.5 flex justify-between">
        <div class="user-left-container">
          <div class="user-container flex gap-x-4 mb-5">
              <div class="avatar-box">
                <img class="avatar w-20 h-20 rounded-full object-cover" alt={user.name} src={avatar}>
              </div>
              <div class="user-details">
                <div class="user-main-detail-container mb-2">
                  <h1 class="user-name-heading text-3xl font-bold mt-3">{user.name}</h1>
                  <p class="text-gray-500 text-sm">email: {user.email}</p>
                </div>
              </div>
          </div>
          <div class="user-follower-stats-container">
            {#key follow_refresh}
              <Stats
                {user}
              />
            {/key}
          </div>
        </div>
        <div class="user-right-container flex flex-col items-center">
          <div class="user-controls-container mb-5">
            {#if current_user && current_user.id !== user.id}
              <div class="follow-button-container">
                {#key follow_refresh}
                  <button
                    type="button"
                    class={ is_following ? 'btn1' : 'bluebtn' }
                    on:click={toggle_follow}
                  >
                    { is_following ? 'Following' : 'Follow' }
                  </button>
                {/key}
              </div>
            {/if}
          </div>
          <div class="user-metadata-container">
            {#key posts_metadata.n_posts}
              <h3>{ `${capitalize(posts_metadata.pluralized_posts_str)}: ${posts_metadata.n_posts}` }</h3>
            {/key}
          </div>
        </div>
    </div>
    {#if posts.length}
      <div class="user-posts-container">
        <ul class="post-list-container">
          <!-- NB: usage of the key post.id in the below each block is necessary -->
          <!-- with the animate to make the entire action of the delete operation responsive -->
          {#each $item_list as post (post.id)}
              <li
                class="animated-li"
                animate:flip={{ duration: 200 }}
                in:scale|local={{ duration: 200, delay: 200 }}
                out:fade|local={{ duration: 200 }}
              >
                <Post
                  {context}
                  {post}
                  {user}
                  current_user={current_user}
                  on:delete={(e) => set_delete_post_confirmation_modal(e.detail)}
                />
              </li>
          {/each}
          <div bind:this={sentinel}></div>
      
          {#if $is_loading}
              <div class="loading-indicator">Loading...</div>
          {/if}
      
          {#if $is_error}
              <div class="error-message">
                  Failed to fetch posts. Please <button on:click={handle_retry}>Retry</button>.
              </div>
          {/if}
        </ul>
      </div>
    {/if}
</div>
<Modal
  {...delete_post_confirmation_modal}
  bind:this={delete_post_modal}
  on:delete={() => delete_post()}
>
  <div slot=body>
    <p>This action cannot be undone.</p>
  </div>
</Modal>
