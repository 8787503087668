<script>
  import { inertia } from '@inertiajs/svelte';
  import { onMount } from 'svelte';
  export let user;
  // export let dropdown_active;

  let dropdown_menu
  let hmenu_active = false;
  let activation_click = true;
  function handle_hmenu_click(){
      hmenu_active = !hmenu_active;

      if(hmenu_active){
          activation_click = true;
          window.addEventListener('click', handle_click_outside_hmenu);
      }
  }

  function handle_click_outside_hmenu(e){
      if(activation_click){
          activation_click = false;
          return;
      }
      const menu_dimensions = dropdown_menu.getBoundingClientRect();
      if(
          e.clientX < menu_dimensions.left ||
          e.clientX > menu_dimensions.right ||
          e.clientY < menu_dimensions.top ||
          e.clientY > menu_dimensions.bottom
      ){
          hmenu_active = false;
          window.removeEventListener('click', handle_click_outside_hmenu);
      }
  }

  function handle_wclick(e){
      const dropdown_menu = document.querySelector('.dropdown-menu');
      // console.log("target: ", e.target);
      if (dropdown_menu && !dropdown_menu.contains(e.target) && e.target.id !== "menu-button"){
          hmenu_active = false;
      }
      // console.log("hmenu_active: ", hmenu_active ? 'yes' : 'no');
  }
  
  let hmenu_x_toggle;
  onMount(() => {
      // window.addEventListener('click', handle_wclick);
      hmenu_x_toggle.addEventListener('click', handle_hmenu_click);
      return () => {
          console.log('removing hmenu toggle listener');
          hmenu_x_toggle.removeEventListener('click', handle_hmenu_click);
      }
  });

</script>

<nav class="navbar">
  <div class="grid place-content-center w-5 h-5 mr-5 cursor-pointer" bind:this={hmenu_x_toggle}> <!-- on:click={() => handle_hmenu_click}  -->
    <div
      id="menu-button"
      class="
        w-8
        { hmenu_active ? 'h-0' : 'h-1' }
        bg-cyan-200
        rounded-full
        before:content-['']
        before:absolute
        before:w-8
        before:h-1
        before:bg-cyan-200
        before:rounded-full
        { hmenu_active ? 'before:translate-y-0' : 'before:-translate-y-2.5' }
        { hmenu_active ? 'before:rotate-45' : '' }
        before:transition-all
        before:duration-150
        after:content-['']
        after:absolute
        after:w-8
        after:h-1
        after:bg-cyan-200
        after:rounded-full
        { hmenu_active ? 'after:translate-y-0' : 'after:translate-y-2.5' }
        { hmenu_active ? 'after:-rotate-45' : '' }
        after:transition-all
        after:duration-150
      "
    ></div>
    {#if hmenu_active}
      <div
        bind:this={dropdown_menu}
        id="mobile-dropdown-menu"
        class="dropdown-menu absolute right-0 z-10 mt-12 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-cyan-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
      >
        <div class="py-1" role="none">
          <a href="/" class="block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0" use:inertia>Home</a>
          <!-- <a href="/help" class="block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1" use:inertia>Help</a> -->
        </div>
        {#if user}
          <div class="py-1" role="none">
            <a href="/users" class="block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2" use:inertia>Users</a>
          </div>
          <div class="py-1" role="none">
            <a href="/users/{user.id}" class="block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-3" use:inertia>Profile</a>
            <a href="/users/{user.id}/edit" class="block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-4">Settings</a>
          </div>
          <div class="py-1" role="none">
            <a href="/logout" class="block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-5" use:inertia="{ {  method: 'delete' } }">Log out</a>
          </div>
        {:else}
          <div class="py-1" role="none">
            <a href="/login" class="block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2" use:inertia>Log in</a>
          </div>
        {/if}
      </div>
    {/if}
  </div>
</nav>
