<script>
  import Header from '@/components/header.svelte';
  import FlashMessages from "@/components/flash_messages.svelte";
  import Footer from "@/components/footer.svelte";
  export let page_title = false;
  let base_title = 'Lentoverse';

  let is_development = import.meta.env.IS_DEVELOPMENT;
  let rails_env = import.meta.env.RAILS_ENV;

  if(is_development){
      console.log('This is the development environment');
      console.log('Rails environment:', rails_env);
  }
</script>

<svelte:head>
  <title>{ page_title ? `${page_title} | ${base_title}` : base_title }</title>
</svelte:head>

  <div class="bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark min-h-screen mb-4">
    <main>
      <Header {is_development} />
      <div id="main-container" class="body-container max-w-7xl mx-auto pt-16 px-4 sm:px-8">
        <FlashMessages />
        <slot />
      </div>
      <Footer />
    </main>
  </div>
