<script>
  import { onMount } from "svelte";

  let current_theme = 'system';

  function set_theme(theme){
      if(theme === 'dark' || ( theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches )){
          document.documentElement.classList.add('dark');
      } else {
          document.documentElement.classList.remove('dark');
      }
      localStorage.setItem( 'theme', theme );
      current_theme = theme;
  }

  onMount(() => {
      const saved_theme = localStorage.getItem('theme') || 'system';
      set_theme( saved_theme );
  });
</script>

<div class="flex items-center justify-center space-x-2">
  <button
    class="px-3 py-1 rounded-md {current_theme === 'light' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}"
    on:click={() => set_theme('light')}
  >
    Light
  </button>
  <button
    class="px-3 py-1 rounded-md {current_theme === 'system' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}"
    on:click={() => set_theme('system')}
  >
    System
  </button>
  <button
    class="px-3 py-1 rounded-md {current_theme === 'dark' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}"
    on:click={() => set_theme('dark')}
  >
    Dark
  </button>
</div>
