<script context="module">
  import Layout from '@/layouts/default.svelte';
  export const layout = Layout;
</script>

<script>
  import PageTitle from '@/components/page_title.svelte';
  import PasswordInput from '@/components/input_password.svelte';
  import { page, useForm } from '@inertiajs/svelte';
  import { bluebtn } from '@/styles.js';
  export let email = $page.props.email;
  export let reset_token = $page.props.token;

  console.log("$page: ", $page);

  let form = useForm({
      password: '',
      password_confirmation: '',
  });

  function submit(){
      // console.log("$form: ", $form)
      $form
      .transform( (data) => ({
          _method: 'patch',
          user: {
              ...data,
          },
          email,
      }))
      .patch(`/password_resets/${reset_token}`);
  }
</script>

<PageTitle>Reset password</PageTitle>

<div class="form-container w-full max-w-xs m-auto">
  <form on:submit|preventDefault={submit} class="text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark shadow-md rounded px-8 pt-6 pb-8 mb-4">
    <input type="hidden" name="email" value={email} />
    <PasswordInput
      label={"New Password"}
      bind:value={$form.password}
      errors={$form.errors}
    />
    <PasswordInput
      label={"Confirmation"}
      bind:value={$form.password_confirmation}
      errors={$form.errors}
    />
    <div class="flex items-center justify-between my-4">
      <button type="submit" class="{bluebtn} w-full" disabled={$form.processing}>Update password</button>
    </div>
  </form>
</div>
